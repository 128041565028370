import { useEffect } from 'react'
import { navigate } from 'gatsby-plugin-intl'

const IndexPage = () => {
  useEffect(() => {
    if (global.window) {
      navigate('/news/relaunch-mitsubishi-electric-factory-automation-emea/')
    }
  }, [])

  return null
}

export default IndexPage
